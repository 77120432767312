<template>
  <Teleport to="#teleports">
    <Transition
      enter-active-class="transition duration-200"
      leave-active-class="transition duration-200"
      enter-from-class="opacity-0 scale-50"
      leave-to-class="opacity-0 scale-50"
    >
      <div
        v-if="modelValue"
        ref="wrapper"
        class="fixed bg-white rounded shadow-lg p-2 w-72 flex flex-col gap-1 z-[9999]"
        :class="className"
        :style="style"
        @click.stop
      >
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: [Boolean, null, undefined],
    required: true,
    default: false,
  },

  reference: {
    type: [Object, null, undefined],
    required: true,
    default: null,
  },

  offset: {
    type: Array,
    default: null,
  },

  class: {
    type: String,
    default: "",
  },

  autoWidth: {
    type: Boolean,
    default: false,
  },

  direction: {
    type: String,
    default: "bottom-left",
    validator(value) {
      return ["bottom-left", "bottom-right", "bottom"].includes(value);
    },
  },
});

const className = computed(() => {
  return props.class;
});

const open = ref(false);
const wrapper = ref(null);
const style = ref({ top: 0, left: 0, transformOrigin: "left top" });
const emit = defineEmits(["update:modelValue", "close"]);

function calculatePosition() {
  const { top, left, width, height } = props.reference.getBoundingClientRect();

  if (props.direction === "bottom-left") {
    style.value.left = `${left}px`;
    style.value.top = `${top + height}px`;
    style.value.transformOrigin = "left top";
  } else if (props.direction === "bottom-right") {
    const elementWidth = wrapper.value.offsetWidth;
    style.value.left = `${left + width - elementWidth}px`;
    style.value.top = `${top + height}px`;
    style.value.transformOrigin = "right top";
  } else if (props.direction === "bottom") {
    const elementWidth = wrapper.value.offsetWidth;
    style.value.left = `${left + width - elementWidth}px`;
    style.value.top = `${top + height}px`;
    style.value.transformOrigin = "top";
  }

  if (props.autoWidth) {
    style.value.width = `${width}px`;
    style.value.left = `${left}px`;
  }

  if (props.offset) {
    style.value.left = `${parseInt(style.value.left) + props.offset[0]}px`;
    style.value.top = `${parseInt(style.value.top) + props.offset[1]}px`;
  }

  if (window.innerHeight - wrapper.value.offsetHeight < parseInt(style.value.top)) {
    style.value.top = `${window.innerHeight - wrapper.value.offsetHeight - 16}px`;
  }
}

function handleOutsideClick(event) {
  if (
    wrapper.value &&
    !wrapper.value.contains(event.target) &&
    !props.reference.contains(event.target)
  ) {
    closeMenu();
  }
}

function openMenu() {
  calculatePosition();
  if (!open.value) {
    document.addEventListener("click", handleOutsideClick);
    window.addEventListener("resize", calculatePosition);
    window.addEventListener("scroll", calculatePosition, true);
  }

  setTimeout(() => {
    open.value = true;
  }, 100);
}

function closeMenu() {
  if (!open.value) {
    return;
  }

  open.value = false;
  emit("update:modelValue", false);
  emit("close");
  document.removeEventListener("click", handleOutsideClick);
  window.removeEventListener("resize", calculatePosition);
  window.removeEventListener("scroll", calculatePosition, true);
}

watch(
  () => props.modelValue,
  (value) => (value ? nextTick(openMenu) : closeMenu())
);

onMounted(() => {
  if (props.modelValue) {
    openMenu();
  }
});

onBeforeUnmount(() => {
  closeMenu();
});
</script>
